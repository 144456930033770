<template>
    <textarea
        type="text"
        class="inputText"
        :value="modelValue"
        @input="updateValue"
        :placeholder="placeholder"
        :maxlength="maxlength"
    ></textarea>
</template>

<script>
export default {
  props: ['modelValue', 'placeholder', 'maxlength'],
  methods: {
    updateValue(event) {
      console.log('hi');
      this.$emit('update:modelValue', event.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.inputText {
    padding: 8px 10px;
    background: #3d424e;
    border: 1px solid #666972;
    border-radius: 5px;
    color: #fff;
    width: 100%;
    font-size: 12px;
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 300;
    resize: none;
    min-height: 80px;
    &::placeholder {
        color: #adadad;
    }
}
</style>
